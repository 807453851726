<template>
  <v-text-field
    dense
    :outlined="outlined"
    :id="id"
    ref="inputRef"
    type="text"
    :value="formattedValue"
    :label="label"
    :filled="filled"
    :disabled="disabled"
    :flat="flat"
    :solo="solo"
    :rules="rules"
    :append-icon="appendIcon"
    :hide-details="hideDetails"
    :loading="loading"
    :readonly="readonly"
    :tabindex="tabindex"
    
  />
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";
import rules from "@/utils/helpers/rules";
import { watch } from "@vue/composition-api";

export default {
  name: "CurrencyInput",
  props: {
    value: [Number, String], // Vue 2: value
    options: Object,
    label: String,
    rules: { type: Array, require: false, default: [] },
    hideDetails: { type: String, require: false, default: "auto" },
    readonly: { type: Boolean, require: false, default: false },
    appendIcon: "",
    filled: { type: Boolean, require: false, default: false },
    loading: { type: Boolean, require: false, default: false },
    disabled: { type: Boolean, require: false, default: false },
    flat: { type: Boolean, require: false, default: false },
    solo: { type: Boolean, require: false, default: false },
    outlined: { type: Boolean, require: false, default: true },
    tabindex: { type: String, require: false, default: "" },
    id: { type: String, require: false, default: "" }
  },
  setup(props) {
    const { formattedValue, inputRef, setOptions, setValue } = useCurrencyInput(
      props.options
    );
    watch(
      () => props.value,
      value => {
        // Vue 2: props.value
        setValue(value);
      }
    );

    watch(
      () => props.options,
      options => {
        setOptions(options);
      }
    );
    return { inputRef, formattedValue };
  }
};
</script>
